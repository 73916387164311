var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "250px", margin: "10px 10px 10px 0" },
              attrs: { placeholder: "输入关键字" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchTwo.apply(null, arguments)
                },
              },
              model: {
                value: _vm.inputValTwo,
                callback: function ($$v) {
                  _vm.inputValTwo = $$v
                },
                expression: "inputValTwo",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "110px" },
                  attrs: { slot: "prepend", placeholder: "请选择" },
                  slot: "prepend",
                  model: {
                    value: _vm.selectTwo,
                    callback: function ($$v) {
                      _vm.selectTwo = $$v
                    },
                    expression: "selectTwo",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "用户名", value: "1" } }),
                  _c("el-option", { attrs: { label: "姓名", value: "2" } }),
                ],
                1
              ),
              _c("i", {
                staticClass: "el-input__icon el-icon-search",
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
            ],
            1
          ),
          _c("el-date-picker", {
            staticClass: "tb-picker",
            attrs: {
              type: "datetimerange",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              "default-time": ["00:00:00"],
            },
            model: {
              value: _vm.chooseDate,
              callback: function ($$v) {
                _vm.chooseDate = $$v
              },
              expression: "chooseDate",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "请选择校区" },
              model: {
                value: _vm.schoolValue,
                callback: function ($$v) {
                  _vm.schoolValue = $$v
                },
                expression: "schoolValue",
              },
            },
            _vm._l(_vm.schoolArray, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-select",
              attrs: { placeholder: "请选择事件类型" },
              model: {
                value: _vm.type,
                callback: function ($$v) {
                  _vm.type = $$v
                },
                expression: "type",
              },
            },
            _vm._l(_vm.typeOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-select",
              attrs: { placeholder: "请选择处理状态" },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            _vm._l(_vm.statusOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.search },
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "学号",
              prop: "studentNo",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "学员姓名",
              prop: "studentName",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "学员校区",
              prop: "studentCampusTitle",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "异常事件", prop: "type" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "责任人", prop: "toUserName" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "事件详情", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("pre", [_vm._v(_vm._s(scope.row.detail))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "处理状态",
              prop: "status",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === "待处理" &&
                    (scope.row.type === "收费价格过低" ||
                      scope.row.type === "学员未计费")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.eventHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.status))]
                        )
                      : scope.row.status === "处理完成"
                      ? _c("span", [_vm._v("处理成功")])
                      : _c("span", [_vm._v(_vm._s(scope.row.status))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "处理描述", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === "学员未计费" &&
                    scope.row.status === "处理完成" &&
                    scope.row.dingtalkProcessInstanceId !== null
                      ? _c("span", [
                          _vm._v("本节课不计费：" + _vm._s(scope.row.remark)),
                        ])
                      : scope.row.type === "收费价格过低" &&
                        scope.row.status === "处理完成" &&
                        scope.row.dingtalkProcessInstanceId !== null
                      ? _c("span", [
                          _vm._v("特殊收费：" + _vm._s(scope.row.remark)),
                        ])
                      : _c("span", [_vm._v(_vm._s(scope.row.remark))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }