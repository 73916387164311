<template>
  <el-row class="table">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-if="firstTab" label="全部异常" :name="allexception">
        <allexception ref="allexception"></allexception>
      </el-tab-pane>
      <el-tab-pane label="我的异常" :name="myexception">
        <myexception  ref="myexception"></myexception>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
  import allexception from '@/views/statistics/anomaly-class'
  import myexception from '@/views/statistics/myanomaly-class'

  export default {
    data() {
      return {
        activeName: 'first',
        firstTab:false,
        allexception:'first',
        myexception:'second'
      };
    },
    components: {
      allexception,
      myexception
    },
    created(){
      let perms = JSON.parse(localStorage.getItem('perms'))
      if(perms.indexOf("exceptionCase:all") != -1) {
        this.firstTab = true
      }
      if(this.firstTab) {
        this.$refs.allexception.getExceptionEventListAll()
        this.$refs.allexception.getCampusAll()
      }else {
        this.myexception = 'first'
        this.$nextTick(()=> {
          this.$refs.myexception.getExceptionEventList()
          this.$refs.myexception.getCampusAll()
        })
      }
    },
    methods:{
      handleClick(tab, event) {
        if(this.activeName === 'second' ) {
          this.$refs.myexception.getExceptionEventList()
          this.$refs.myexception.getCampusAll()
        }else if(this.activeName === 'first') {
          if(this.firstTab) {
            this.$refs.allexception.getExceptionEventListAll()
            this.$refs.allexception.getCampusAll()
          }else {
            this.$refs.myexception.getExceptionEventList()
            this.$refs.myexception.getCampusAll()
          }
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../style/table.scss';
</style>
