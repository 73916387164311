var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm.firstTab
            ? _c(
                "el-tab-pane",
                { attrs: { label: "全部异常", name: _vm.allexception } },
                [_c("allexception", { ref: "allexception" })],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "我的异常", name: _vm.myexception } },
            [_c("myexception", { ref: "myexception" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }