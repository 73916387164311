<template>
  <el-row class="table">
    <el-row class="row-bg">
      <el-input
        placeholder="输入关键字"
        v-model="inputValTwo"
        style="width: 250px;margin:10px 10px 10px 0"
        @keyup.enter.native="searchTwo"
      >
        <el-select v-model="selectTwo" slot="prepend" placeholder="请选择" style="width: 110px;">
          <el-option label="用户名" value="1"></el-option>
          <el-option label="姓名" value="2"></el-option>
        </el-select>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-date-picker
        class="tb-picker"
        v-model="chooseDate"
        type="datetimerange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00']"
      ></el-date-picker>
      <el-select v-model="schoolValue" placeholder="请选择校区" class="tb-smselect">
        <el-option v-for="item in schoolArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="type" placeholder="请选择事件类型" class="tb-select">
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="status" placeholder="请选择处理状态" class="tb-select">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
    </el-row>

    <el-table :data="dataList" border fit highlight-current-row class="tb-list">
      <el-table-column align="center" label="时间">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="学号" prop="studentNo" width="150"></el-table-column>
      <el-table-column align="center" label="学员姓名" prop="studentName" width="120"></el-table-column>
      <el-table-column align="center" label="学员校区" prop="studentCampusTitle"></el-table-column>
      <el-table-column align="center" label="异常事件" prop="type"></el-table-column>
      <el-table-column align="center" label="责任人" prop="toUserName"></el-table-column>
      <el-table-column align="center" label="事件详情" width="300">
        <template slot-scope="scope">
          <pre>{{scope.row.detail}}</pre>
        </template>
      </el-table-column>
      <el-table-column align="center" label="处理状态" prop="status" width="150">
        <template slot-scope="scope">
          <el-button type="text" v-if="scope.row.status === '待处理' && (scope.row.type === '收费价格过低' || scope.row.type === '学员未计费' )" @click="eventHandle(scope.row)">{{scope.row.status}}</el-button>
          <span v-else-if="scope.row.status === '处理完成'">处理成功</span>
          <span v-else>{{scope.row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="处理描述" width="300">
        <template slot-scope="scope">
          <span v-if="scope.row.type === '学员未计费' && scope.row.status === '处理完成' && scope.row.dingtalkProcessInstanceId !== null">本节课不计费：{{scope.row.remark}}</span>
          <span v-else-if="scope.row.type === '收费价格过低' && scope.row.status === '处理完成' && scope.row.dingtalkProcessInstanceId !== null">特殊收费：{{scope.row.remark}}</span>
          <span v-else>{{scope.row.remark}}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import { getExceptionEventListAll, eventHandle } from '@/api/statistics'
import { getCampusAll } from '@/api/senate/class'
import TestCase from "../../components/research/question-manage/testCase";

export default {
  components: {TestCase},
  data () {
    return {
      chooseDate: '',
      schoolValue: '', // 校区id
      schoolArray: [], // 校区列表
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      type: null,
      inputValTwo:'',
      selectTwo:'1',
      status:'',
      statusOptions: [
        {
          label: '待处理',
          value: '待处理'
        },{
          label: '处理中',
          value: '处理中'
        }, {
          label: '处理完成',
          value: '处理完成'
        }, {
          label: '处理失败',
          value: '处理失败'
        }
      ],
      typeOptions: [
         {
          label: '收费价格过低',
          value: '收费价格过低'
        },{
          label: '无剩余课时',
          value: '无剩余课时'
        }, {
          label: '学员未计费',
          value: '学员未计费'
        }
      ]
    }
  },
  created () {
    this.getExceptionEventListAll()
    this.getCampusAll()
  },
  methods: {
    async getExceptionEventListAll () {
      const res = await getExceptionEventListAll({
        minHappenTime: Boolean(this.chooseDate) ? this.chooseDate[0] : null,
        maxHappenTime: Boolean(this.chooseDate) ? this.chooseDate[1] : null,
        campusId: this.schoolValue,
        type: this.type,
        status:this.status,
        loginName:this.selectTwo === '1' ? this.inputValTwo :'',
        userName:this.selectTwo === '2' ? this.inputValTwo :'',
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 获取全部校区
    async getCampusAll () {
      const res = await getCampusAll()
      this.schoolArray = res.body
    },

    search () {
      this.pagination.currentPage = 1
      this.getExceptionEventListAll()
    },

    reset () {
      this.chooseDate = ''
      this.schoolValue = ''
      this.status = ''
      this.inputValTwo = ''
      this.type = ''
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getExceptionEventListAll()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getExceptionEventListAll()
    },

    eventHandle (row) {
      this.$prompt('发起钉钉审批，请输入审批缘由', {
        confirmButtonText: '发起申请',
        cancelButtonText: '取消',
        inputErrorMessage: '输入不能为空',
        inputValidator: (data)=>{
          if( data === null){
            return '输入不能为空'
          }else if(data.trim().length < 1) {
            return '输入不能为空'
          }
        },
        type: 'warning'
      })
        .then(async (data) => {
          await eventHandle({
            exceptionEventId: row.id,
            content:data.value
          })
          window.$msg('发送成功，请提醒责任人注意查收')
          this.getExceptionEventListAll()
        })
    }
  }
}
</script>

<style lang="scss" scope>
@import "../../style/table.scss";
</style>
